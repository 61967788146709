<template>
  <base-section
    id="theme-features"
    class="repeating-gradient"
  >
    <base-section-heading title="Escape Rooms">
      <div v-if="language === '/en'">
        ENGLISH - Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga.
      </div>
      <div v-else>
        DEUTSCH - Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga.
      </div>
    </base-section-heading>

    <v-container>
      <v-row>

        <v-col
          id="dusseldorf"
          cols="12"
          md="4"
        >
          <v-card
            class="mx-auto"
            max-width="344"
          >
            <v-img
              :src="require('@/assets/blackshepherdsfinal_dusseldorf.jpg')"
              height="200px"
            ></v-img>

            <v-card-title>
              Düsseldorf
            </v-card-title>

            <v-card-subtitle>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in!
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                href="https://final-escape.com/wuppertal/"
                text
              >
                Link zum Spiel
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          id="koln"
          cols="12"
          md="4"
        >
          <v-card
            class="mx-auto"
            max-width="344"
          >
            <v-img
              :src="require('@/assets/blackshepherdsfinal_koln.jpg')"
              height="200px"
            ></v-img>

            <v-card-title>
              Köln
            </v-card-title>

            <v-card-subtitle>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in!
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                href="https://final-escape.com/wuppertal/"
                text
              >
                Link zum Spiel
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          id="wuppertal"
          cols="12"
          md="4"
        >
          <v-card
            class="mx-auto"
            max-width="344"
          >
            <v-img
              :src="require('@/assets/blackshepherdsfinal_wuppertal.jpg')"
              height="200px"
            ></v-img>

            <v-card-title>
              Wuppertal
            </v-card-title>

            <v-card-subtitle>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in!
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                text
                href="https://final-escape.com/wuppertal/"
              >
                Link zum Spiel
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Pixel Perfect Design',
          icon: 'mdi-fountain-pen-tip',
        },
        {
          title: 'Retina Ready',
          icon: 'mdi-cellphone',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Easily Customizable',
          icon: 'mdi-pencil-box-outline',
        },
        {
          title: 'Image Parallax',
          icon: 'mdi-image-size-select-actual',
        },
        {
          title: 'Seo Optimized',
          icon: 'mdi-ice-pop',
        },
        {
          title: '24/7 Support',
          icon: 'mdi-help-circle-outline',
        },
      ],
      language: '/de',
    }),

    watch: {
      $route (to, from) {
        this.language = this.$route.path
      },
    },

    created () {
      this.language = this.$route.path
    },
  }
</script>

<style>
.background-themefeatures{
  background-color: #4c7888;
}
  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
                        #4c7888,
                        #4c7888 5px,
                        #4c7888 5px,
                        #4c7888 10px
                      );
  }
</style>
